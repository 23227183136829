.row {
  max-width: 680px;
}

.nav-wrapper {

}

.navbar {
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 3;
    padding: 0 12px;
    border-bottom: 1px solid rgba(110,120,152,.25);
    height: 72px;
    width: 100%;
    background: #fff;
}

.dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.navbar-right .navbar-item:last-of-type {
    margin-right: 0;
}

.navbar-right .navbar-item:not(.main-btn) {
    color: #6e7898;
}

.unauthed-menu {
    display: none;
}

@media (max-width: 640px) {
  .dropdown .unauthed-menu svg {
      margin-right: 0;
  }
  .dropdown .unauthed-menu {
      display: block;
  }
  .unauthed-large {
      display: none;
  }
}

.navbar-right {
    padding-left: 32px;
    padding-right: 24px;
    border-left: 1px solid rgba(110,120,152,.25);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown .unauthed-ul {
    top: 32px;
}

.dropdown ul {
    list-style-type: none;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    right: 0;
    width: 200px;
    background-color: #fff;
    font-weight: 500;
    position: absolute;
    box-shadow: 0 20px 40px rgb(0 0 0 / 13%);
    z-index: 1;
}

.dropdown li {
    padding: 16px;
    display: flex;
    align-items: center;
}

.navbar-right .navbar-item:last-of-type {
    margin-right: 0;
}
.navbar-right .navbar-item {
    margin-right: 48px;
}

.navbar .logo {
    font-weight: 600;
    color: #6e7898;
    letter-spacing: 2px;
}

.navbar-left {
    padding-left: 24px;
}

.navbar .logo:hover {
    color: #000;
}

.container {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}

@media screen and (min-width: 992px) {
  .container {
    max-width: 750px;
    width:60%;
  }
}

.timbrBtn {
  background-color: #e3bab3;
}

.timbrBtn:hover {
  background-color: #e9c9c3;
}

.socialBtnAlt {
  color: #ffffff;
  font-size: 25px;
  margin: 0 10px;
}

.navbarFont {
  color: #000000;
  /*font-weight: 600;*/
  font-size: 2.28rem;
  font-weight: bolder;
}

/*.navbarFont:hover {
  color: #e9c9c3;
}*/

.fade-in {
  opacity: 1;
  transition: opacity 1.5s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 1.5s ease;
}

input:focus {
  border-bottom: 1px solid #849974;
  box-shadow: 0 1px 0 0 #849974;
}

input:not(.browser-default):focus:not([readonly]) {
  border-bottom: 1px solid #849974 !important;
  box-shadow: 0 1px 0 0 #849974 !important;
}

input:not(.browser-default):focus:not([readonly])+label  {
  color: #849974 !important;
}

.chip>img {
  margin: 0 4px 0 8px;
  height: 24px;
  width: 24px;
}

.searchWrapper {
  border: none !important;
}

.spinner {
    animation: spin infinite 3s linear;
    font-size: 25px;

    /*You can increase or decrease the timer (5s) to
     increase or decrease the speed of the spinner*/
  }

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centerAlign {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 25vh;
}

@media (max-width: 640px) {
  .centerAlign {
    display: block;
    justify-content: center;
    align-items: center;
    min-height: 0;
  }

}
